export const ShipmentTypeFilters = [
  {
    code: '0',
    description: 'Ocean & Air',
    value: '5'
  },
  {
    code: '1',
    description: 'Ocean',
    value: '3'
  },
  {
    code: '2',
    description: 'Air',
    value: '2'
  }
];
