import { ShipmentTypeFilters } from '../shipment-type-filters';
import moment from 'moment';

import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { API_DATE_FORMAT } from 'app/globals/constants';

export class MyShipmentsSearchForm extends NavCarrierFormGroup {

  constructor(structure: FormGroupStructure) {
    super(structure);
    this.validate();
  }

  getBookTypeSelection(): number {
    return this.get('bookType').value;
  }

  private get bookTypeStatusParams(): BookTypeStatusParams {
    const formValues = this.value;
    return {
      BookTypeFilter: formValues.bookType,
      Status: formValues.status
    };
  }

  private get dateRangeParams(): MyShipmentsDateRangeParams {
    const dates: DateRangeGroupValues = this.value.dateRange;
    let PickUpDate = null;
    let DeliveryDate = null;

    if (dates.pickup.start || dates.pickup.end) {
      PickUpDate = {
        Start: this.parseDate(dates.pickup.start),
        End: this.parseDate(dates.pickup.end)
      };
    }
    if (dates.drop.start || dates.drop.end) {
      DeliveryDate = {
        Start: this.parseDate(dates.drop.start),
        End: this.parseDate(dates.drop.end)
      };
    }

    return {PickUpDate, DeliveryDate};
  }

  private parseDate(date?: moment.Moment): string {
    if (date) {
      date = moment(date);

      date.hours(0);
      date.minute(0);
      date.seconds(0);

      return date.format(API_DATE_FORMAT);
    }
  }

  private get locationParams(): MyShipmentsLocationParams {
    const location: LocationGroupValues = this.value.location;
    const origin = location.origin;
    const destination = location.destination;

    let OriginStates = null;
    let DestinationStates = null;

    if (origin && origin.country && origin.country.code) {
      OriginStates = [{
        CountryCode: origin.country.code,
        StateCodes: origin.stateProv && origin.stateProv.code ? [origin.stateProv.code] : null
      }];
    }
    if (destination && destination.country && destination.country.code) {
      DestinationStates = [{
        CountryCode: destination.country.code,
        StateCodes: destination.stateProv && destination.stateProv.code ? [destination.stateProv.code] : null
      }];
    }

    return {OriginStates, DestinationStates};
  }

  private get referenceParams(): MyShipmentsReferenceParams | undefined {
    const reference: ReferenceGroupValues = this.value.reference;
    if (reference.searchNumberType == null || reference.searchNumberType === '') {
      // just don't add the field to the request.
      return;
    }
    const response = {};
    response[reference.searchType.code] = reference.searchNumberType;

    return response;
  }

  public toSearchRequest(): MyShipmentsBaseSearchRequest {
    const data: MyShipmentsBaseSearchRequest = {
      ...this.dateRangeParams,
      ...this.locationParams,
      ...this.bookTypeStatusParams,
      ...this.referenceParams
    };

    // strip all `null` or empty values from the query.
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === '|') && delete data[key]);

    return data;
  }
}

export const MY_SHIPMENTS_SEARCH_FORM_KEY = 'MY_SHIPMENTS_SEARCH_FORM';

export const createMyShipmentsSearchFormStructure = (): FormGroupStructure => groupStructure({
  dateRange: groupStructure({
    pickup: groupStructure(
      {
        start: fieldStructure(null),
        end: fieldStructure(null)
      },
      [v.validateDateRangeOrder, v.validateDateRangeComplete]
    ),
    drop: groupStructure(
      {
        start: fieldStructure(null),
        end: fieldStructure(null)
      },
      [v.validateDateRangeOrder, v.validateDateRangeComplete]
    )
  }),
  bookType: fieldStructure(ShipmentTypeFilters[0].value),
  status: fieldStructure(null),
  reference: groupStructure({
    searchType: fieldStructure({
      code: 'LoadNumber',
      description: 'LOAD_NUMBER'
    }),
    searchNumberType: fieldStructure()
  }),
  location: groupStructure({
    origin: groupStructure({
      country: fieldStructure(),
      stateProv: fieldStructure()
    }),
    destination: groupStructure({
      country: fieldStructure(),
      stateProv: fieldStructure()
    })
  })
});
