import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { ShipmentSummary } from 'shared/models/shipments/shipment-summary.model';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { SearchResponse } from 'shared/models/search-response.model';

export const SET_CURRENT_SHIPMENT_SEARCH_VALUES = 'SET_CURRENT_SHIPMENT_SEARCH_VALUES';
export const SET_PENDING_SHIPMENT_SEARCH_VALUES = 'SET_PENDING_SHIPMENT_SEARCH_VALUES';
export const RESET_CURRENT_SHIPMENT_SEARCH_VALUES = 'RESET_CURRENT_SHIPMENT_SEARCH_VALUES';
export const RESET_PENDING_SHIPMENT_SEARCH_VALUES = 'RESET_PENDING_SHIPMENT_SEARCH_VALUES';

export const setCurrentSearchValues = (values: MyShipmentsSearchFormValues) => ({type: SET_CURRENT_SHIPMENT_SEARCH_VALUES, values});
export const setPendingSearchValues = (values: MyShipmentsSearchFormValues) => ({type: SET_PENDING_SHIPMENT_SEARCH_VALUES, values});
export const resetCurrentSearchValues = () => ({type: RESET_CURRENT_SHIPMENT_SEARCH_VALUES});
export const resetPendingSearchValues = () => ({type: RESET_PENDING_SHIPMENT_SEARCH_VALUES});

export const SET_SHIPMENT_SEARCH_ERROR = 'SET_SHIPMENT_SEARCH_ERROR';
export const RESET_SHIPMENT_SEARCH_ERROR = 'RESET_SHIPMENT_SEARCH_ERROR';

export const setSearchError = (error: string | JSX.Element) => ({type: SET_SHIPMENT_SEARCH_ERROR, error});
export const resetSearchError = () => ({type: RESET_SHIPMENT_SEARCH_ERROR});

export const SET_SHIPMENT_PAGINATION_PARAMS = 'SET_SHIPMENT_PAGINATION_PARAMS';
export const SET_SHIPMENT_SORT_PARAMS = 'SET_SHIPMENT_SORT_PARAMS';
export const SET_SHIPMENT_SEARCH_PARAMS = 'SET_SHIPMENT_SEARCH_PARAMS';

export const setPaginationParams = (params: PaginationParams) => ({type: SET_SHIPMENT_PAGINATION_PARAMS, params});
export const setSortParams = (params: MyShipmentSortParams) => ({type: SET_SHIPMENT_SORT_PARAMS, params});
export const setSearchParams = (params: MyShipmentsSearchRequest) => ({type: SET_SHIPMENT_SEARCH_PARAMS, params});

export const MY_SHIPMENTS_SEARCH = 'MY_SHIPMENTS_SEARCH';

export const MY_SHIPMENTS_SEARCH_START = `${REQUEST_START}:${MY_SHIPMENTS_SEARCH}`;
export const MY_SHIPMENTS_SEARCH_SUCCESS = `${REQUEST_SUCCESS}:${MY_SHIPMENTS_SEARCH}`;
export const MY_SHIPMENTS_SEARCH_FAILURE = `${REQUEST_FAILURE}:${MY_SHIPMENTS_SEARCH}`;

export const searchMyShipments = (criteria: MyShipmentsSearchRequest) => ({type: MY_SHIPMENTS_SEARCH_START, criteria});
export const myShipmentsSearchSuccess = (payload: SearchResponse<ShipmentSummary>) => ({type: MY_SHIPMENTS_SEARCH_SUCCESS, payload});
export const myShipmentsSearchFailure = (error: AjaxError) => ({type: MY_SHIPMENTS_SEARCH_FAILURE, error});
